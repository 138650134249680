class Toc {
  constructor(rootElement, options) {

    this.documentArea = document.querySelector(rootElement)
    //親要素がない場合は終了
    if (!this.documentArea) return;

    const defaultOptions = {
      heading: 'h1,h2,h3,h4,h5,h6',
      insertTarget: '.js-toc',
      idPrefix: 'toc-link',
    }

    this.options = this.mergeOptions(defaultOptions, options)

    this.headingElements = this.documentArea.querySelectorAll(this.options.heading)

    this.insertTarget = document.querySelector(this.options.insertTarget)

    this.uid = 0

    this.init();

  }

  init() {

    this.createToc()
    this.link()

  }


  mergeOptions(defaultOptions, options) {
    const mergeOptions = Object.assign(defaultOptions, options || {});
    return mergeOptions
  }


  //見出しの骨組みを作成
  createToc() {

    const tocBase = document.createElement('ol')
    tocBase.setAttribute('data-toc-level', '1')

    let level = 1
    let group = 0
    let beforeRank = 7
    let rootRabk = 7
    let parent = tocBase //次に見出しが入る要素を親

    this.headingElements.forEach((el) => {

      let rank = Number(el.tagName.substring(1))

      const ol = document.createElement('ol')
      
      if(beforeRank >= rank && rootRabk >= rank) {
        //beforeRankが現在のrankより高い場合
        tocBase.appendChild(this.createLink(el));
        parent = tocBase.lastChild //次に見出しが入る要素を親を設定
        rootRabk = rank
        level = 1
        group += 1
      } else {
        if(beforeRank < rank) {
          //beforeRankより現在のrnkが高い場合
          level += 1
          ol.setAttribute('data-toc-level', level)
          ol.setAttribute('data-toc-group', group)
          if(parent) {
            parent.appendChild(ol);
            parent.lastChild.appendChild(this.createLink(el));
            parent = parent.lastChild.querySelector("li") //次に見出しが入る要素を親を設定

          }
          // parent
          // console.log(parent)

        } else if(beforeRank == rank) {
          //beforeRankと現在のrankが同じな場合
          //つまり同じレベルの見出しが連続する場合
          parent = tocBase.querySelector(`[data-toc-level="${level}"][data-toc-group="${group}"]`) //次に見出しが入る要素を親を設定
          if(parent) parent.appendChild(this.createLink(el))

        } else {
          const diff = beforeRank - rank;
          let localParent = tocBase.querySelector(`[data-toc-level="${level - diff}"][data-toc-group="${group}"]`);
          if(localParent) {

            localParent.appendChild(this.createLink(el))
            parent = localParent.lastChild
          }

          level = level - diff

        }
      }
      //前のrankとして現在のrankを保存
      beforeRank = rank
    })

    // console.log(tocBase)

    this.insertTarget.appendChild(tocBase)

  }

  createLink(el) {
    const uid = () => `${this.options.idPrefix}-${this.uid++}`;
    el.id = el.id || uid(); //すでにid属性が設定されている場合はそれを使用する
    let li = `
    <li>
      <a href="#${el.id}" class="toc-link">${el.innerText}</a>  
    </li>`
    // let li = document.createElement('li');
    // let a = document.createElement('a');
    // a.href = `#${el.id}`;
    // a.innerText = el.innerText;
    // a.className = 'LINK_CLASS_NAME';
    // li.appendChild(a);
    const convertNode = this.htmlToNode(li)
    return convertNode[1];
  }
  
  //HTML文字列をnodeに変換
  htmlToNode(htmlStr) {
    if (!htmlStr || typeof htmlStr !== 'string') return;
    
    var tmpElmt = document.createElement('div'),
    i = 0, len = 0, nodes = [];
    
    // 高速処理するが対応ブラウザを考えinnerHTMLを使用
    tmpElmt.innerHTML = htmlStr; // tmpElmt.insertAdjacentHTML('beforeend', htmlStr);
    
    return tmpElmt.childNodes;
  }

  link() {
    const links = document.querySelectorAll(`.toc-link`);
    links.forEach((el) => {
      el.addEventListener("click",(e)=>{
          const targetEl = document.querySelector(el.hash);
          scrollTo(0, window.pageYOffset + targetEl.getBoundingClientRect().top - 80);
          e.preventDefault();
          e.stopPropagation();
      });
    });
  }


}


new Toc(".articleDetail", {
  heading: 'h1,h2,h3,h4'
})