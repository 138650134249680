// lazyloadプラグインです

//読み込み
//import LazyLoad from 'path/to/lazyload';

//実行
// const hoge = new LazyLoad(".lazyload", {
//   rootMargin: "300px"
// })

const lazyload = (() => {

  return class LazyLoad {
    constructor(
      targetElement = ".lazyload",
      options
    ) {

      this.targetElement = targetElement
      if (!document.querySelectorAll(this.targetElement)) return;

      const defaultOptions = {
        src: "data-src",
        srcset: "data-srcset",
        // target: ".lazyload",
        root: null,
        rootMargin: "0px",
        threshold: 0
      }

      this.options = this.mergeOptions(defaultOptions, options)

      this.init()

    }

    init() {
      this.observer()
    }

    //Defaultオプションと入力オプションをマージ
    mergeOptions(defaultOptions, options) {
      const mergeOptions = Object.assign(defaultOptions, options || {});
      // mergeOptions.targets = [...document.querySelectorAll(`${mergeOptions.target}`)]
      mergeOptions.images = [...document.querySelectorAll(`${this.targetElement}[${mergeOptions.src}]`)]

      return mergeOptions
    }

    observer() {

      //IntersectionObserverが使いないブラウザの場合（多分IE）
      if (!window.IntersectionObserver) {
        this.loadImages()
        return
      }

      //IntersectionObserverのオプションを定義
      let observerConfig = {
        root: this.options.root,
        rootMargin: this.options.rootMargin,
        threshold: [this.options.threshold]
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {

          //対象の要素が画面内に入ってきたとき
          if (entry.isIntersecting) {
            //対象の監視をやめる（1回のみということ）
            observer.unobserve(entry.target);

            //監視対象のdata-srcの内容を取得
            let src = entry.target.getAttribute(this.options.src);

            //監視対象がimgタグの場合
            if ("img" === entry.target.tagName.toLowerCase()) {
              if (src) {
                //data-srcの内容をsrcにセット
                entry.target.src = src;
              }
            }

          }
        })

      }, observerConfig);

      this.options.images.forEach(image => {
        observer.observe(image);
      })

    }

    //画像を強制的にセット
    loadImages() {
      this.options.images.forEach(image => {
        let src = image.getAttribute(this.options.src);
        if ("img" === entry.target.tagName.toLowerCase()) {
          if (src) {
            //data-srcの内容をsrcにセット
            entry.target.src = src;
          }
        }
      })
    }

  }

})();

export default lazyload