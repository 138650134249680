import Swiper, { Navigation, Scrollbar } from 'swiper';
const mql = window.matchMedia('(max-width: 768px)');

Swiper.use([Navigation ,Scrollbar]);

const option = {
  slidesPerView: 1,

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  scrollbar: {
    el: '.swiper-scrollbar',
  },

};

// const swiper = new Swiper('.swiper', option);

function initSwiper() {
  if (mql.matches) {
    /* ビューポートが 600px 幅より狭い */
    const swiper = new Swiper('.swiper', option);
  } else {
    /* ビューポートが 600px 幅より広い */
  }
}
  
  // mql.addListener(initSwiper);
  initSwiper();

//全てのモジュールをまとめてインポート
// import Swiper, { Navigation, Scrollbar, Autoplay } from 'swiper';

// /* ビューポートが 600px 幅より狭い */
// const swiper = new Swiper('.swiper', {
//   // // Optional parameters
//   // loop: true,
//   modules: [Navigation,Autoplay],
//   slidesPerView: 1,
//   spaceBetween: 10,

//   autoplay: {
//     delay: 3000,
//     disableOnInteraction: false,
//   },

//   // Navigation arrows
//   navigation: {
//     nextEl: '.swiper-button-next',
//     prevEl: '.swiper-button-prev',
//   },

//   // And if we need scrollbar
//   // scrollbar: {
//   //   el: '.swiper-scrollbar',
//   // },

// });

// const mql = window.matchMedia('(max-width: 768px)');
// const swiper = null;

// function initSwiper() {
//   if (mql.matches) {
//     /* ビューポートが 600px 幅より狭い */
//     const swiper = new Swiper('.swiper', {
//       // // Optional parameters
//       // loop: true,
//       modules: [Navigation],
//       slidesPerView: 1,
// 			spaceBetween: 10,

// 			autoplay: {
// 				delay: 3000,
// 				disableOnInteraction: false,
// 			},
    
//       // Navigation arrows
//       navigation: {
//         nextEl: '.swiper-button-next',
//         prevEl: '.swiper-button-prev',
//       },
    
//       // And if we need scrollbar
//       scrollbar: {
//         el: '.swiper-scrollbar',
//       },

//     });
//   } else {
//     /* ビューポートが 600px 幅より広い */
//     if (swiper) {
//       swiper.destroy();
//     }
//   }
// }

// mql.addListener(initSwiper);
// initSwiper();